import { GET } from "../../services/api";
import {
  updateMessageloader,
  updateReconnectMessage,
} from "../../store/features/Messages.slice";
import store from "../../store/store";
import { ApiURL } from "../constant";
import { QB } from "../QuickBlox_initiation";
import { checkQBexpiry } from "../utils";

function QB_onReconnectListener() {
  store.dispatch(updateReconnectMessage(""));
  store.dispatch(updateMessageloader(false));
  QB.getSession((err: any, session: any) => {
    if (err) {
      console.log(err);
      store.dispatch(GET("usersession", ApiURL.USER_MESSAGE_SESSION)()); //
    } else {
      const isExpired = checkQBexpiry(session);
      if (isExpired) {
        store.dispatch(GET("usersession", ApiURL.USER_MESSAGE_SESSION)());
      }
    }
  });
}

export default QB_onReconnectListener;
