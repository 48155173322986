import { useState, useEffect } from "react";

const useNetworkConnection = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateIsOnline = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(
    () => {
      window.addEventListener("online", updateIsOnline);
      window.addEventListener("offline", updateIsOnline);
    },
    [] // Only re-call effect if value or delay changes
  );
  return isOnline;
};

export default useNetworkConnection;
