import { QB, QB_CONFIG } from "./QuickBlox_initiation";
import QB_onMessageListener from "./QuickBlox/QB_onMessageListener";
import QB_onDisconnectedListener from "./QuickBlox/QB_onDisconnectedListener";
import QB_onSessionExpiredListener from "./QuickBlox/QB_onSessionExpiredListener";
import QB_onReconnectListener from "./QuickBlox/QB_onReconnectListener";
import QB_onSystemMessageListener from "./QuickBlox/QB_onSystemMessageListener";

QB.initWithAppId(
  Number(process.env.REACT_APP_QUICKBLOX_APPLICATION_ID),
  process.env.REACT_APP_QUICKBLOX_ACCOUNT_KEY,
  QB_CONFIG
);

QB.chat.onMessageListener = QB_onMessageListener;
QB.chat.onSystemMessageListener = QB_onSystemMessageListener;
QB.chat.onDisconnectedListener = QB_onDisconnectedListener;
QB.chat.onReconnectListener = QB_onReconnectListener;
QB.chat.onSessionExpiredListener = QB_onSessionExpiredListener;
