import dayjs from "dayjs";
import { messageDateLimit } from "./constant";

const calcDaysPassed = (givenDate: Date, currentDate: Date) => {
  const currentHour = currentDate.getHours() * 60 * 60 * 1000;
  const currentMinutes = currentDate.getMinutes() * 60 * 1000;
  const currentsec = 24 * 60 * 60 * 1000 - currentHour + currentMinutes;
  // console.log(
  //   (currentDate.getTime() - givenDate.getTime() + currentsec) /
  //     (60 * 24 * 60 * 1000)
  // );
  // console.log(currentDate.getTime(), givenDate.getTime(), currentsec);
  return Math.trunc(
    Math.abs(currentDate.getTime() - givenDate.getTime() + currentsec) /
      (60 * 60 * 24 * 1000)
  );
};
const timeStampBasedOnGivenTime = (time: string | number = "") => {
  const givenDate = new Date(time);
  const currentDate = new Date();
  const givendate = givenDate.getDate();
  const month = givenDate?.toLocaleString("default", { month: "short" });
  const formattedtime = messageSentTiming(time);

  const daysPassed = calcDaysPassed(givenDate, currentDate);
  if (daysPassed === 0) return `Today, ${formattedtime}`;
  if (daysPassed === 1) return `Yesterday, ${formattedtime}`;
  return `${givendate} ${month}, ${formattedtime}`;
};

const dateFormatBasedOnCurrentDate = (date = "") => {
  const givenDate = new Date(date);
  const currentDate = new Date();
  const daysPassed = calcDaysPassed(givenDate, currentDate);
  const month = givenDate?.toLocaleString("default", { month: "long" });
  const givendate = givenDate.getDate();
  const year = givenDate.getFullYear();

  if (daysPassed === 0) return `Today`;
  if (daysPassed === 1) return `Yesterday`;
  return `${month} ${givendate} , ${year}`;
};

const diff_hours = (date: string) => {
  if (date === "") return "";
  const updateDate = new Date(date);
  const currentDate = new Date();

  let diff = (currentDate.getTime() - updateDate.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
};

const diff_mins = (date: string) => {
  if (date === "") return "";
  const updateDate = new Date(date);
  const currentDate = new Date();

  let diff = (currentDate.getTime() - updateDate.getTime()) / 60000;
  return Math.abs(Math.floor(diff));
};

const messageSentTiming = (time: string | number) => {
  return dayjs(time).format("hh:mm a");
};

const getLastResponse = (date: string) => {
  if (date === "") return "";
  const diffHour = diff_hours(date);

  if (diffHour === "") return "";

  if (diffHour === 0) {
    const diffMin = diff_mins(date);
    if (diffMin === 0) {
      return `Last Response just now`;
    } else if (diffMin === 1) {
      return `Last Response 1 min ago`;
    }
    return `Last Response ${diffMin} mins ago`;
  }
  if (diffHour < 24) {
    return `Last Response ${diffHour} hours ago`;
  }
  const updateDate = new Date(date);
  if (diffHour < 48) {
    return `Last Response Yesterday`;
  }

  return `Last Response ${updateDate.getDate()}/${
    updateDate.getMonth() + 1
  }/${updateDate.getFullYear()}`;
};

const getLocalTimeDate = (date: string) => {
  return new Date(date).toString();
};

const getExactHours = (current: number, created: number) => {
  let hours = Math.abs(current - created);
  return `${hours}h ago`;
};

const getExactMins = (current: number, created: number) => {
  let hours = Math.abs(current - created);
  return `${hours}m ago`;
};

const getDateTime = (data: string) => {
  let currentDate = dayjs().format("DD-MM-YYYY");
  let createdAt = dayjs(data);
  let formattedDate = createdAt.format("DD MMM hh:mm a");
  if (currentDate === createdAt.format("DD-MM-YYYY")) {
    if (parseInt(createdAt.format("m")) <= 1) {
      return "now";
    } else if (parseInt(createdAt.format("m")) <= 59) {
      return getExactMins(
        parseInt(dayjs().format("m")),
        parseInt(createdAt.format("m"))
      );
    } else if (parseInt(createdAt.format("H")) < 24) {
      return getExactHours(
        parseInt(dayjs().format("H")),
        parseInt(createdAt.format("H"))
      );
    } else {
      return formattedDate;
    }
  } else {
    return formattedDate;
  }
};

export {
  timeStampBasedOnGivenTime,
  dateFormatBasedOnCurrentDate,
  diff_hours,
  getLastResponse,
  getLocalTimeDate,
  getDateTime,
  messageSentTiming,
};

export const checkWithinMessageRange = (date: string | number = "") => {
  const givenDate = new Date(date);
  const currentDate = new Date();
  const daysPassed = calcDaysPassed(givenDate, currentDate);

  if (daysPassed >= messageDateLimit) {
    return true;
  }
  return false;
};
