import {
  IMYSpickupaddressList,
  ImysSaleResponse,
  IMYSshowDom,
} from "../../interfaces/ImakeYourSale";
import { MYSPickupAddressLabelWrapper } from "./MakeYourSaleStyled";
import { IstateSelect } from "../../interfaces/ICommonData";
import railImg from "../../assets/images/mys/rail.png";
import containerImg from "../../assets/images/mys/container.png";
import truckImg from "../../assets/images/mys/truck.png";
import { UploadFileStatus } from "antd/es/upload/interface";
import store from "../../store/store";
import { resetImageList } from "../../store/features/CreateOfferOrderFormReducer.slice";
import {
  resetMYSforms,
  updatePricingDetailsShowFlow,
} from "../../store/features/MakeYourSale.slice";
import { GTM_submitoffer, GTM_submitorder } from "../../utils/GTM";
import { heap_formFourOffer, heap_formFourOrder } from "./MYS_heap";

export const MYStext: any = {
  max_step: 4,
  form_title: {
    1: "Sale Terms",
    2: "Loads To Be Shipped",
    3: "Additional Information",
    4: "Review",
  },
  how_price: "How would you like to price your material",
  how_price_info:
    "Please select the option below that best describes your experience level in selling recyclables.",
  novice_desc:
    "Please fill and select your options below to calculate the price for your recycling.",
  novice: "New to Recycling",
  experienced: "Experienced Recycler",
  form_one: {
    enter_weight: `Do you have estimated weight of the material you are selling?`,
    freight_option: `Do you want us to pick up or do you want to deliver?`,
    load_terms: `Load Terms`,
  },
  form_two: {
    info_text: {
      fob_domestic_truck:
        "Per your selection, this material will be picked up in Truck Loads. Based on the total weight you are looking to sell, here's our recommended load-breakdown for optimal pricing. You can change the loads if you choose. Pricing will be updated accordingly.",

      fas_domestic_truck:
        "Per your selection, this material will be delivered in Truck Loads. Based on the total weight you are looking to sell, here's our recommended load-breakdown for optimal pricing. You can change the loads if you choose. Pricing will be updated accordingly.",

      fob_domestic_rail:
        "Per your selection, this material will be picked up in Railcar Loads. Based on the total weight you are looking to sell, here's our recommended load-breakdown for optimal pricing. You can change the loads if you choose. Pricing will be updated accordingly.",

      fas_domestic_rail:
        "Per your selection, this material will be delivered in Railcar Loads. Based on the total weight you are looking to sell, here's our recommended load-breakdown for optimal pricing. You can change the loads if you choose. Pricing will be updated accordingly.",

      fob_export_ship:
        "Per your selection, this material will be picked up in Export Container Loads. Based on the total weight you are looking to sell, here's our recommended load-breakdown for optimal pricing. You can change the loads if you choose. Pricing will be updated accordingly.",

      fas_export_ship:
        "Per your selection, this material will be Delivered in Export Container Loads. Based on the total weight you are looking to sell, here's our recommended load-breakdown for optimal pricing. You can change the loads if you choose. Pricing will be updated accordingly.",

      fob_export_rail:
        "Per your selection, this material will be picked up in Railcar Loads. Based on the total weight you are looking to sell, here's our recommended load-breakdown for optimal pricing. You can change the loads if you choose. Pricing will be updated accordingly.",

      fas_export_rail:
        "Per your selection, this material will be delivered in Railcar Loads. Based on the total weight you are looking to sell, here's our recommended load-breakdown for optimal pricing. You can change the loads if you choose. Pricing will be updated accordingly.",
    },
  },
  request_flow: {
    infoText: (text = "0") => {
      return ` Unfortunately we currently do not have an upfront price for asd ${MYSweightFormatter(
        text
      )} lbs. You can propose a price or request a price from us.`;
    },
  },
};

export const MYSminWeightSelect = [
  {
    value: "lb",
    label: "lbs",
  },
  {
    value: "st",
    label: "ton",
  },
];

export const MYS_initial_delivery_schedule = {
  drop_off_date: null,
  loads: "",
};

export const MYSformOneInitialValues = {
  sale_type: null,
  material_type: null,
  sell_option: null,
  freight_option: null,
  load_terms: null,
  min_weight: null,
  min_weight_unit: "lb",
  pickup_address: null,
  port: null,
  min_weight_load: null,
  new_zip_code: null,
  new_state: null,
  new_address: null,
  new_city: null,
};

export const MYSinitialShowDom: IMYSshowDom = {
  enter_weight: false,
  sell_option: false,
  freight_option: false,
  material_type: false,
  load_terms: false,
  weight_load: false,
  port: false,
  pickup: false,
  delivery: false,
  image: false,
  notes: false,
  new_pickup: false,
};

export const MYSunitConversion: any = {
  lb: "lbs",
  st: "tons",
  gt: "gt",
  ton: "ton",
  mt: "mt",
};
export const MYSHeapUnitConversion: any = {
  LB: "LB",
  ST: "ST",
  GT: "GT",
  TON: "ST",
  MT: "MT",
};
export const MYSsingularunitConversion: any = {
  lb: "lb",
  st: "ton",
  ton: "ton",
  gt: "gt",
  mt: "mt",
};

export const MYSflow = {
  NOVICE: "novice",
  EXPERIENCED: "experienced",
};

export const MYSsale_flow_type: any = {
  novice: "TOTAL_WEIGHT",
  experienced: "WEIGHT_PER_LOAD",
};

export const MYSfreightOptionText = {
  FOB_Domestic: "FOB Domestic",
  FAS_Domestic: "FAS Domestic",
  FOB_Export: "FOB Export",
  FAS_Export: "FAS Export",
};

export const freightTypeCopyNovice = {
  domestic: {
    FAS: "Seller(You) Delivers",
    FOB: "GP Picks up",
  },
  export: {
    FAS: "Seller(You) Delivers",
    FOB: "GP Picks up",
  },
};

export const freightTypeCopyExp = {
  domestic: {
    FAS: "Seller(You) Delivers (DAP)",
    FOB: "GP Picks up (FOB)",
  },
  export: {
    FAS: "Seller(You) Delivers (FAS)",
    FOB: "GP Picks up (FOB)",
  },
};

export const MYSdomesticRadios = [
  {
    value: "FOB Domestic",
    label: {
      novice: freightTypeCopyNovice.domestic.FOB,
      experienced: freightTypeCopyExp.domestic.FOB,
    },
  },
  {
    value: "FAS Domestic",
    label: {
      novice: freightTypeCopyNovice.domestic.FAS,
      experienced: freightTypeCopyExp.domestic.FAS,
    },
  },
];
//eslint-disable-next-line
export const MYSexportRadios = [
  {
    value: "FOB Export",
    label: {
      novice: freightTypeCopyNovice.domestic.FOB,
      experienced: freightTypeCopyExp.export.FOB,
    },
  },
  {
    value: "FAS Export",
    label: {
      novice: freightTypeCopyNovice.domestic.FAS,
      experienced: freightTypeCopyExp.export.FAS,
    },
  },
];

export const MYSfreightOptionData = {
  FOB: ["FOB Export", "FOB Domestic"],
  FAS: ["FAS Export", "FAS Domestic"],
};

export const MYSsaleFreightOptionData = {
  FOB: ["FOB_EXPORT", "FOB_DOMESTIC"],
  FAS: ["FAS_EXPORT", "FAS_DOMESTIC"],
};

export const MYSfreightOptionValues: any = {
  "FOB Domestic": freightTypeCopyExp.domestic.FOB,
  "FAS Domestic": freightTypeCopyExp.domestic.FAS,
  "FOB Export": freightTypeCopyExp.export.FOB,
  "FAS Export": freightTypeCopyExp.export.FAS,
};

export const MYSfreightOptionNoviceValues: any = {
  "FOB Domestic": freightTypeCopyNovice.domestic.FOB,
  "FAS Domestic": freightTypeCopyNovice.domestic.FAS,
  "FOB Export": freightTypeCopyNovice.export.FOB,
  "FAS Export": freightTypeCopyNovice.export.FAS,
};

export const MYSdomesticLoadTerms = [
  { value: "truck", label: "Truck", icon: truckImg },
  { value: "rail", label: "Railcars", icon: railImg },
];

export const MYSLoadTermsData: any = {
  truck: "Truck",
  ship: "Container",
  rail: "Rail",
};

export const MYSLoadTermsApiData: any = {
  Truck: "truck",
  Container: "ship",
  Rail: "rail",
};

export const MYSloadterms = [
  { value: "truck", label: "Truck", icon: truckImg },
  { value: "rail", label: "Railcars", icon: railImg },
  { value: "ship", label: "Containers", icon: containerImg },
];

export const MYSexportLoadTerms = [
  { value: "ship", label: "Ship Containers", icon: containerImg },
  { value: "rail", label: "Railcars", icon: railImg },
];
export const MYSLoadTermsMap: any = {
  ship: containerImg,
  container: containerImg,
  rail: railImg,
  truck: truckImg,
};
export const MYSweightFormatter = (
  weight: string = "",
  decimal = 0,
  skip_replace = false
) => {
  let min_weight = weight;
  if (!skip_replace) min_weight = min_weight.replace(/\D/g, "");
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: decimal,
  });
  return formatter.format(+min_weight).replace("$", "");
};
export const MYSgetOnlyNumber = (weight: string = "") => {
  return +weight.replace(/\D/g, "");
};

export const mysGetAddressFormatter = (props: IMYSpickupaddressList) => {
  const address = [];
  if (props.address) {
    address.push(props.address);
  }
  if (props.address_2) {
    address.push(props.address_2);
  }
  if (props.address_3) {
    address.push(props.address_3);
  }
  return [address.join(" "), props.city, props.state, props.zip_code].join(
    ", "
  );
};
export const MYSPickupAddressLabel = (props: IMYSpickupaddressList) => {
  const fullAddress = mysGetAddressFormatter(props);

  return (
    <MYSPickupAddressLabelWrapper>
      <h3>{props.name}</h3>
      <p>{`(${fullAddress})`} </p>
    </MYSPickupAddressLabelWrapper>
  );
};

export const mysNewStateSelectOptions = (
  state_list: IstateSelect[] = [],
  search: string = ""
) => {
  let filterData: IstateSelect[] = [];

  const list: any = state_list.filter((el: IstateSelect) => {
    if (el?.name.toLowerCase().startsWith(search?.toLowerCase())) {
      filterData.push(el);
      return false;
    }
    if (el?.name?.toLowerCase().includes(search.toLowerCase())) return true;
    else return false;
  });
  filterData = [...filterData, ...list];
  return filterData.map((item: IstateSelect) => {
    return { label: item.name, value: item.id };
  });
};

export const MYSnavigatePath = (
  id = "",
  zip_code = "",
  step: string | number = "1",
  draft = false,
  draft_id = ""
) => {
  return `/home/${
    draft ? `draft/${draft_id}` : "grades"
  }/${id}/${zip_code}/${step}`;
};

export const MYSgetCachedKey = (
  form_code: 1 | 2 | 3 | 4 = 1,
  id = "",
  zipcode = "",
  response = false,
  trade = false
) => {
  const form = {
    1: "MYS_form_one",
    2: "MYS_form_two",
    3: "MYS_form_three",
    4: "MYS_form_four",
  };

  return `${form[form_code]}_${id}_${zipcode}${response ? "_response" : ""}${
    trade ? "_trade" : ""
  }`;
};

export const getMYSValidNextPath = (id = "", zipcode = "", draft_id = "") => {
  const path = `/home/grades/${id}/${zipcode}/`;
  const draftPath = `/home/draft/${draft_id}/${id}/${zipcode}/`;
  const saleConfirmation = `/home/sale-confirmation/${id}/${zipcode}`;
  return [
    path + 1,
    path + 2,
    path + 3,
    path + 4,
    saleConfirmation,
    draftPath + 1,
    draftPath + 2,
    draftPath + 3,
    draftPath + 4,
  ];
};

export const MYScontextType = {
  NO_UPFRONT_PRICE: "NO_UPFRONT_PRICE",
  UNACCEPTED_PACKING_TYPE: "UNACCEPTED_PACKING_TYPE",
  PARTIAL_LOAD: "PARTIAL_LOAD",
};

export const MYStradeType = {
  OFFER: "OFFER",
  ORDER: "ORDER",
  HYBRID: "HYBRID",
};

export const MYSsaleFlowType = {
  WEIGHT_PER_LOAD: "WEIGHT_PER_LOAD",
  TOTAL_WEIGHT: "TOTAL_WEIGHT",
};
export const MYSsaleFlowApiType: any = {
  WEIGHT_PER_LOAD: "experienced",
  TOTAL_WEIGHT: "novice",
};

export const MYSshipmentTypes: any = {
  FAS_EXPORT: "FAS Export",
  FAS_DOMESTIC: "FAS Domestic",
  FOB_EXPORT: "FOB Export",
  FOB_DOMESTIC: "FOB Domestic",
};

export const MYSImageListForDraft = (grade_images: string[]) => {
  const currentImageList: {
    uid: string;
    name: string;
    url: string;
    status: UploadFileStatus;
  }[] = [];
  if (grade_images && grade_images?.length > 0) {
    grade_images?.forEach((item: string) => {
      const fileName = item?.replace(
        `${process.env.REACT_APP_USER_S3_CLOUDFRONT_URL}public/`,
        ""
      );
      const uploadedFileName = {
        uid: fileName,
        name: fileName,
        status: "done" as UploadFileStatus,
        url: item,
      };
      currentImageList.push(uploadedFileName);
    });
  }
  return currentImageList;
};

export const clearMYScachedata = (id = "", zip_code = "") => {
  const cache_form_1 = MYSgetCachedKey(1, "" + id, zip_code);
  const cache_form_2 = MYSgetCachedKey(2, "" + id, zip_code);
  const cache_form_3 = MYSgetCachedKey(3, "" + id, zip_code);
  const cache_form_1_res = MYSgetCachedKey(1, "" + id, zip_code, true);
  const cache_form_2_res = MYSgetCachedKey(2, "" + id, zip_code, true);
  const cache_form_3_res = MYSgetCachedKey(3, "" + id, zip_code, true);
  const cache_form_1_trade = MYSgetCachedKey(1, "" + id, zip_code, false, true);
  const cache_form_2_trade = MYSgetCachedKey(2, "" + id, zip_code, false, true);
  const cache_form_3_trade = MYSgetCachedKey(3, "" + id, zip_code, false, true);

  localStorage.removeItem(cache_form_1);
  localStorage.removeItem(cache_form_2);
  localStorage.removeItem(cache_form_3);
  localStorage.removeItem(cache_form_1_res);
  localStorage.removeItem(cache_form_2_res);
  localStorage.removeItem(cache_form_3_res);
  localStorage.removeItem(cache_form_1_trade);
  localStorage.removeItem(cache_form_2_trade);
  localStorage.removeItem(cache_form_3_trade);
  store.dispatch(resetImageList());
  store.dispatch(updatePricingDetailsShowFlow({ ...MYSinitialShowDom }));
  store.dispatch(resetMYSforms());
};

export const MYSloadDistributionFormater = (
  load_distribution: number[] = []
) => {
  return load_distribution.reduce((prev, el: number, index) => {
    const load_id = index + 1;
    const keyWord = "replace" + el;
    if (prev[keyWord]) {
      prev[keyWord].push(load_id);
    } else prev[keyWord] = [load_id];
    return prev;
  }, {} as { [id: string]: number[] });
};

export const mysHeapConst = {
  start_sale: "Start Sale",
  form_one_submit: "Submit Sale Terms",
  form_one_hybrid: "View Sell Partial Loads Choice Pop Up",
  form_one_hybrid_order: "Choose Sell Partial Loads Choice Pop Up",
  form_one_hybrid_offer: "Choose Sell Partial Loads Choice Pop Up",
  form_two_submit: "Review Weight and Price or Price Request",
  form_three_submit: "Submit Additional Information",
  form_four_offer: "Submit Offer",
  form_four_order: "Submit Order",
  save_draft: "Save Draft",
  close_save_draft: "Close Save Draft",

  select_recyler_type: "Select Recycler Type",
  select_packing_type: "Select Packing Type",
  select_sell_option: "Select Sell Option",
  select_freight_type: "Select Freight Type",
  select_load_terms: "Select Load Terms",
  enter_weight: "Enter Weight",
  enter_loads: "Enter Loads",
  select_location: "Select Location",
  view_assistance: "View Make Your Sale Exit Communication Pop Up",
  close_assistance: "Close Make Your Sale Exit Communication Pop Up",
  schedule_call: "Schedule Call",
  contact_trader: "Contact Trader",
  dont_show_anymore: "Do Not Show Again",
};

export const heap_source_const = {
  from_communication: "MYS Exit Communication Pop Up",
  from_feedback: "MYS Exit Feedback Form",
};

export const mysMaterialList = [
  {
    key: "BALED",
    value: "Baled",
  },
  {
    key: "GAYLOARD_SUPERSACK",
    value: "In Gaylords / Supersacks",
  },
  {
    key: "COMPACTOR",
    value: "Compactor",
  },
  {
    key: "OPEN_TOP_BUMPSTER",
    value: "Open Top Dumpster",
  },
  {
    key: "OTHER",
    value: "Other",
  },
];

export const MYSmaterialsLabel: any = {
  BALED: "Baled",
  GAYLOARD_SUPERSACK: "In Gaylords / Supersacks",
  COMPACTOR: "Compactor",
  OPEN_TOP_BUMPSTER: "Open Top Dumpster",
  OTHER: "Other",
};

export const MYSonCompleteTracking = (
  isRequested = false,
  data: ImysSaleResponse = {} as ImysSaleResponse
) => {
  let grade_name = "";
  let grade_type = "";
  let ship_type = "";
  let offer_id = "";
  let order_id = "";
  let price = 0;
  let loads = 0;
  let min_weight = 0;
  let total_weight = 0;
  let unit_price = 0;
  if (data.grade.name) grade_name = data.grade.name;

  if (data.grade.category_name) grade_type = data.grade.category_name;

  if (data.freight_type && data.sell_type)
    ship_type = data.freight_type + " " + data.sell_type;

  if (data.offer_details && data.offer_details.offer_id)
    offer_id = "" + data.offer_details.offer_id;

  if (data.id) order_id = "" + data.id;

  if (data.total_price) price = +data.total_price;
  if (data.loads) loads = data.loads;
  if (data.min_weight) min_weight = data.min_weight;
  if (data.total_weight) total_weight = data.total_weight;
  if (data.unit_price) unit_price = data.unit_price;

  if (isRequested) {
    const gtmOffer = {
      grade: grade_name,
      grade_type: grade_type,
      ship_type: ship_type,
      offer_id: offer_id,
    };
    GTM_submitoffer(gtmOffer);
    heap_formFourOffer();
  } else {
    const gtmOrder = {
      grade_name: grade_name,
      grade_type: grade_type,
      ship_type: ship_type,
      order_id: order_id,
      price: price,
      loads: loads,
      min_weight: min_weight,
      total_weight: total_weight,
      unit_price: unit_price,
    };
    GTM_submitorder(gtmOrder);
    heap_formFourOrder();
  }
};

export const MYSgetFreightType = ({
  freight_type = "",
  sale_flow_type = "",
  sell_type = "",
}) => {
  const isFAS = freight_type === "FAS";
  const isNovice = sale_flow_type === "TOTAL_WEIGHT";
  const isDomestic = sell_type.toLowerCase() === "domestic";

  let freightType = "";

  if (isNovice) {
    freightType = isFAS
      ? freightTypeCopyNovice.export.FAS
      : freightTypeCopyNovice.export.FOB;
    if (isDomestic) {
      freightType = isFAS
        ? freightTypeCopyNovice.domestic.FAS
        : freightTypeCopyNovice.domestic.FOB;
    }
  } else {
    freightType = isFAS
      ? freightTypeCopyExp.export.FAS
      : freightTypeCopyExp.export.FOB;
    if (isDomestic) {
      freightType = isFAS
        ? freightTypeCopyExp.domestic.FAS
        : freightTypeCopyExp.domestic.FOB;
    }
  }

  return freightType;
};
