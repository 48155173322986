import { GET } from "../../services/api";
import store from "../../store/store";
import { ApiURL } from "../constant";
import QB_sessionLogout from "./QB_sessionLogout";

export const QB_onSessionExpiredListener = (error: any) => {
  if (error) {
    console.log("onSessionExpiredListener - error: ", error);
  } else {
    QB_sessionLogout();
    setTimeout(() => {
      store.dispatch(GET("usersession", ApiURL.USER_MESSAGE_SESSION)());
    }, 2000);
  }
};

export default QB_onSessionExpiredListener;
