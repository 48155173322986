import { GET } from "../../services/api";
import { updateUserId } from "../../store/features/Messages.slice";
import store from "../../store/store";
import { ApiURL } from "../constant";
import { QB } from "../QuickBlox_initiation";
import QB_connect from "./QB_connect";

export const QB_initiat = (token: string) => {
  if (token) {
    QB.startSessionWithToken(token, function (err: any, mySession: any) {
      if (err) {
        console.log("Error in start session with token - ", token, err);
        store.dispatch(GET("usersession", ApiURL.USER_MESSAGE_SESSION)());
      } else {
        store.dispatch(updateUserId(mySession.session.user_id));
        QB_connect(mySession);
      }
    });
  }
};
