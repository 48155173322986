import {
  updateMessageloader,
  updateReconnectMessage,
} from "../../store/features/Messages.slice";
import store from "../../store/store";

function QB_onDisconnectedListener() {
  store.dispatch(updateReconnectMessage("Reconnecting..."));
  store.dispatch(updateMessageloader(true));
}
export default QB_onDisconnectedListener;
