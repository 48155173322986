import { setSessionToEmpty } from "../../store/features/Messages.slice";
import store from "../../store/store";
import { QB } from "../QuickBlox_initiation";

const QB_sessionLogout = () => {
  QB.chat.disconnect();
  QB.destroySession(function (error: any) {});
  QB.logout(function (error: any, result: any) {
    store.dispatch(setSessionToEmpty());
  });
};

export default QB_sessionLogout;
