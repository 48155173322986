import React from "react";
import styled from "styled-components";
import ChatBotResponsiveIcon from "../Icons/ChatBotResponsiveIcon";
import { fontMedium12 } from "../../utils/styleConsts";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  toggleShowChatBot,
  updateMinimizeChatBot,
} from "../../store/features/Common.slice";
import { heap_viewchatbot } from "./chatbot_heapevents";
import { useLocation } from "react-router-dom";

const Wrapper = styled.div`
  height: 3.6rem;
  width: 100%;
  background: linear-gradient(144.64deg, #283238 15.09%, #285e5f 242.34%);
  display: flex;
  padding: 0 1.6rem;
  gap: 1.4rem;
  align-items: center;
  cursor: pointer;
  h4 {
    ${fontMedium12}
    color:var(--WHITE);
  }
`;
const ChatBotResponsive = () => {
  const dispatch = useAppDispatch();

  const web_chatbot_enabled = useAppSelector(
    (state) => state.ProfileReducer.web_chatbot_enabled
  );
  const location = useLocation();
  const toggleChat = () => {
    heap_viewchatbot({
      screen: location.pathname,
    });
    dispatch(toggleShowChatBot(true));
    dispatch(updateMinimizeChatBot(false));
  };
  if (!web_chatbot_enabled) return <></>;
  return (
    <Wrapper onClick={toggleChat}>
      <ChatBotResponsiveIcon />
      <h4>Ask hubbIT assistant</h4>
    </Wrapper>
  );
};

export default ChatBotResponsive;
