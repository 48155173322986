import { I_QB_sendMessageMail } from "../../interfaces/IQBinterfaces";
import { messages_events } from "../eventTrackingConstants";
import { QB } from "../QuickBlox_initiation";
import QB_prepareMessageFormat from "./QB_prepareMessageFormat";
import QB_sendEmail from "./QB_sendEmail";

const QB_sendMessage = (data: {
  text: string;
  dialog_id: string | number;
  opponentId: number[];
  type: number;
  topic_type: string;
  sendasclient?: number;
  button_options?: any;
  sendmail?: boolean;
  msg_style?: string;
  mys_count?: string;
  mys_type?: string;
  mys_grade_name?: string;
}) => {
  const {
    text = "",
    dialog_id = "",
    opponentId = [],
    type = 2,
    topic_type = "",
    sendasclient = 0,
    button_options = {},
    sendmail = true,
    msg_style = "",
    mys_type = "",
    mys_count = "",
    mys_grade_name = "",
  } = data;
  let message: any = QB_prepareMessageFormat({
    text: text,
    dailog_id: dialog_id,
    type: type,
    sendasclient: sendasclient,
    questions_option: button_options,
    msg_style,
    mys_count,
    mys_type,
    mys_grade_name,
  });

  const dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialog_id);
  try {
    if (type === 2) message.id = QB.chat.send(dialogJid, message);
    else message.id = QB.chat.send(...opponentId, message);

    const mailMsg: I_QB_sendMessageMail = {
      recepients: opponentId,
      dialog_id: dialog_id,
      message: text,
      created_on: new Date().toISOString(),
      attachment: null,
    };

    if (sendmail) {
      QB_sendEmail(mailMsg);
      heap.track(messages_events.SEND_MESSAGE, {
        chat_type: topic_type.toLowerCase(),
        message_type: "text",
      });
    }
  } catch (e: any) {
    if (e.name === "ChatNotConnectedError") {
      console.log(e);
    }
  }
};

export default QB_sendMessage;
