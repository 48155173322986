import React, { useEffect } from "react";
import {
  ApiURL,
  SCREEN_TYPE,
  USER_NOT_FOUND,
  maxNetworkConnectionReconnect,
  status,
} from "./utils/constant";
import { GET } from "./services/api";
import { checkQBexpiry, logoutUser } from "./utils/utils";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import theme from "./assets/theme";
import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import useScreenType from "./hooks/useScreenType";
import { updateToggleLoader } from "./store/features/Common.slice";
import { QB_initiat } from "./utils/QuickBlox/QB_initiat";
import useNetworkConnection from "./hooks/useNetworkConnection";
import {
  updateNetworkConnectionRetry,
  updateRetryQB,
} from "./store/features/Messages.slice";
import { QB } from "./utils/QuickBlox_initiation";

const themeWrapper: any = document.querySelector("html");

const InitializeData = () => {
  for (const [key, value] of Object.entries(theme["default"])) {
    themeWrapper.style.setProperty(key, value);
  }
  const isOnlineCheck = useNetworkConnection();

  const isAuthenticated = useAppSelector(
    (state) => state.AuthReducer.isAuthenticated
  );
  const token = useAppSelector((state) => state.AuthReducer.token);

  const session_id = useAppSelector(
    (state) => state.MessagesReducer.session_id
  );

  const qb_initiated = useAppSelector(
    (state) => state.MessagesReducer.qb_initiated
  );
  const chatbot_id = useAppSelector(
    (state) => state.MessagesReducer.chatbot_id
  );
  const retry_QB = useAppSelector((state) => state.MessagesReducer.retry_QB);

  const connection_retry = useAppSelector(
    (state) => state.MessagesReducer.connection_retry
  );

  const screenType = useScreenType();
  const mobileView =
    screenType === SCREEN_TYPE.TABLET || screenType === SCREEN_TYPE.MOBILE;

  const dispatch = useAppDispatch();

  const tagManagerArgs: TagManagerArgs = {
    gtmId: "GTM-TSTC59QC",
  };
  useEffect(() => {
    if (isAuthenticated && token !== "") {
      dispatch(GET("usersession", ApiURL.USER_MESSAGE_SESSION)()).catch(
        (error) => {
          // if the session is invalid then route to login page
          if (error.error === USER_NOT_FOUND) logoutUser();
        }
      );

      dispatch(GET("common/appAlerts", ApiURL.APP_ALERTS)());
      dispatch(GET("userlist", ApiURL.MESSAGE_USER_LIST)());
      dispatch(GET("notificationList", ApiURL.NOTIFICATION_LIST + "&page=1")());
      dispatch(GET("messageQuestion", ApiURL.MESSAGE_QUESTIONS)());
      dispatch(
        GET("grades/gradeCategory", ApiURL.GRADE_CATEGORY + "?grades=false")()
      );
      if (mobileView) dispatch(updateToggleLoader(true));
      dispatch(
        GET(
          "offersListHome",
          `${ApiURL.OFFERS_LIST}/?page=1&size=${2}&status=${
            status.waiting_for_supplier
          }`
        )()
      ).then(() => {
        if (mobileView) dispatch(updateToggleLoader(false));
      });
    }
    TagManager.initialize(tagManagerArgs);
    //eslint-disable-next-line
  }, [dispatch, isAuthenticated, token]);

  useEffect(() => {
    if (session_id !== "" && chatbot_id !== null) {
      QB_initiat(session_id);
    }
  }, [session_id, chatbot_id]);

  useEffect(() => {
    const triggerApi = () => {
      if (retry_QB && isOnlineCheck) {
        if (
          isAuthenticated &&
          token &&
          token !== "" &&
          connection_retry < maxNetworkConnectionReconnect
        ) {
          setTimeout(() => {
            dispatch(GET("usersession", ApiURL.USER_MESSAGE_SESSION)())
              .then((res) => {
                if (res.payload.status === 200) {
                  dispatch(updateRetryQB(false));
                } else {
                  dispatch(updateNetworkConnectionRetry());
                }
              })
              .catch((err) => {
                dispatch(updateNetworkConnectionRetry());
              });
          }, 5000);
        }
      }
    };
    if (isOnlineCheck && qb_initiated) {
      QB.getSession((err: any, session: any) => {
        if (err) {
          triggerApi();
        } else {
          const isExpired = checkQBexpiry(session);
          if (isExpired) {
            triggerApi();
          }
        }
      });
    }
    if (!isOnlineCheck) {
      dispatch(updateRetryQB(true));
    }
    //eslint-disable-next-line
  }, [retry_QB, isOnlineCheck, connection_retry, qb_initiated]);

  return <></>;
};

export default InitializeData;
