import { signout } from "../store/features/Auth.actions";
import { resetAuthReducer, setLoginStatus } from "../store/features/Auth.slice";
import {
  resetCommonReducer,
  updateToggleLoader,
} from "../store/features/Common.slice";
import {
  resetProfileReducer,
  setUserData,
} from "../store/features/Profile.slice";
import store from "../store/store";
import { UserPermissibleRoles, shipment_types } from "./constant";
import OneSignal from "react-onesignal";
import { resetMessageReducer } from "../store/features/Messages.slice";
import { resetCreateOfferOrderFormReducer } from "../store/features/CreateOfferOrderFormReducer.slice";
import { resetDraftReducer } from "../store/features/Draft.slice";
import { resetGradeReducer } from "../store/features/Grade.slice";
import { resetNotificationReducer } from "../store/features/Notification.slice";
import { resetOffersReducer } from "../store/features/Offers.slice";
import { resetOffersActionsReducer } from "../store/features/OffersActions.action";
import { resetOrderReducer } from "../store/features/Order.slice";
import { resetOtpReducer } from "../store/features/Otp.slice";
import { resetPickupLocationReducer } from "../store/features/PickupLocations.slice";
import { resetPricingReducer } from "../store/features/Pricing.slice";
import { resetMakeYourSaleReducer } from "../store/features/MakeYourSale.slice";
import QB_sendMessage from "./QuickBlox/QB_sendMessage";
import { jwtDecode } from "jwt-decode";

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string?.slice(1);
};

export const formatName = (string: string) => {
  const nameArray = string?.split(" ");
  const capitalizedArray = nameArray?.map((element) =>
    capitalizeFirstLetter(element)
  );
  return capitalizedArray?.join(" ");
};

export const getInitials = (string: string) => {
  if (!string) return "";
  const words = string.trim().split(/[ ]+/);
  let initials = words[0][0];
  if (words.length > 1) initials += words[1][0];
  return initials.toUpperCase();
};

export const getUserType = (user: { ["custom:user_role"]: string }) => {
  const userList = JSON.parse(user["custom:user_role"]).map((el: string) =>
    el.toLowerCase() === "superadmin" ? "admin" : el.toLowerCase()
  );
  return userList.includes("admin") ? "admin" : userList[0];
};

export const hasNonPermissibleRoles = (user: {
  ["custom:user_role"]: string;
}) => {
  return JSON.parse(user["custom:user_role"]).some((role: string) =>
    UserPermissibleRoles.includes(role.toLowerCase())
  );
};

export const WeightConversion = (unit: string) => {
  switch (unit?.toLowerCase()) {
    case "st":
      return 1 / 2000;

    case "ton":
      return 1 / 2000;

    case "mt":
      return 1 / 2204.62;

    case "gt":
      return 1 / 2240;

    default:
      return 1;
  }
};
export const logoutUser = () => {
  OneSignal.removeExternalUserId();
  store.dispatch(updateToggleLoader(true));
  store.dispatch(signout()).then(() => {
    store.dispatch(setUserData(null));
    store.dispatch(setLoginStatus(false));
    localStorage.clear();
    store.dispatch(updateToggleLoader(false));
    resetReducer();
  });
};

export const resetReducer = () => {
  store.dispatch(resetAuthReducer());
  store.dispatch(resetMessageReducer());
  store.dispatch(resetCommonReducer());
  store.dispatch(resetDraftReducer());
  store.dispatch(resetCreateOfferOrderFormReducer());
  store.dispatch(resetGradeReducer());
  store.dispatch(resetNotificationReducer());
  store.dispatch(resetOffersReducer());
  store.dispatch(resetOffersActionsReducer());
  store.dispatch(resetOrderReducer());
  store.dispatch(resetOtpReducer());
  store.dispatch(resetPickupLocationReducer());
  store.dispatch(resetProfileReducer());
  store.dispatch(resetPricingReducer());
  store.dispatch(resetMakeYourSaleReducer());
};

export const isEmpty = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export const constructGradeFilterStringQuery = (
  dealType?: string,
  category?: number,
  categoryA?: number,
  categoryB?: number,
  searchParam?: string,
  sort?: "default" | "asc" | "desc"
) => {
  let filter_string = "";
  if (category) {
    filter_string = filter_string + "&category_id=" + category;
  }
  if (categoryA) {
    filter_string = filter_string + "&sub_category_1_id=" + categoryA;
  }
  if (categoryB) {
    filter_string = filter_string + "&sub_category_2_id=" + categoryB;
  }
  if (dealType && dealType !== "all") {
    filter_string = filter_string + "&type=" + dealType;
  }
  if (searchParam !== "" && searchParam) {
    filter_string = filter_string + "&query=" + encodeURIComponent(searchParam);
  }
  if (sort === "asc") {
    filter_string += "&sort=name&order=asc";
  }
  if (sort === "desc") {
    filter_string += "&sort=name&order=desc";
  }
  return filter_string;
};

export const getShipmentType = (shipmentType: string) => {
  switch (shipmentType) {
    case "FAS_DOMESTIC": {
      return shipment_types.FAS_DOMESTIC;
    }
    case "FAS_EXPORT": {
      return shipment_types.FAS_EXPORT;
    }
    case "FOB_DOMESTIC": {
      return shipment_types.FOB_DOMESTIC;
    }
    case "FOB_EXPORT": {
      return shipment_types.FOB_EXPORT;
    }
    default: {
      return "";
    }
  }
};
export const formatCurrency = (val: any) => {
  return val?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
export const formatLBCurrency = (val: any) => {
  return val?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
  });
};
export const formatTotalPriceCurrency = (val: any) => {
  return val?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const formatTransactionWeight = (weight: any) => {
  let formattedWeight = "";
  if (weight.toString()?.length >= 4) {
    formattedWeight = `${Math.round(weight / 1000)}K`;
  } else {
    formattedWeight = weight;
  }
  return `${formattedWeight} ${weight > 1 ? "lbs" : "lb"}`;
};
export const formatInputNumber = (value: any) => {
  if (!value) {
    if (value !== "0") return "";
  }
  const number = value.toString();
  const parts = number.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const parseInputNumber = (value: any) => {
  if (!value) {
    if (value !== "0") return "";
  }

  const numberWithoutCommas = value.replace(/,/g, "");
  return parseFloat(numberWithoutCommas);
};

export const kFormatter = (num: number) => {
  const absNum = Math.abs(num);
  const formattedNum =
    absNum > 999
      ? `${Math.sign(num) * Math.floor(absNum / 1000)}k`
      : `${Math.sign(num) * absNum}`;

  return formattedNum;
};

export const parseIntegerInputValue = (value: any) => {
  const numberWithoutCommas = value.replace(/,/g, "");
  return Math.round(numberWithoutCommas);
};

export const sendChatBotMessage = ({
  type = "",
  text = "",
  questions_type = "",
  questions_id = 0,
}) => {
  const state = store.getState();
  const { chatbot_dialog_id, chatbot_id, user_id, global_admin_details } =
    state.MessagesReducer;
  const { chatbot_const_text } = state.CommonReducer;
  let chatbotData = {
    text: text,
    dialog_id: chatbot_dialog_id,
    opponentId: [
      +user_id,
      chatbot_id ? +chatbot_id : 0,
      +global_admin_details.id,
    ],
    type: 2,
    topic_type: "direct",
    sendasclient: 0,
    button_options: {},
  };
  switch (type) {
    case "greeting":
      chatbotData = {
        ...chatbotData,
        text: chatbot_const_text.intro,
        sendasclient: 1,
        button_options: {
          questions_type: "categories",
          questions_id: undefined,
        },
      };
      break;

    case "helpful":
      chatbotData = {
        ...chatbotData,
        text: chatbot_const_text.helpful_note,
        sendasclient: 1,
        button_options: {
          questions_type: "helpful",
        },
      };
      break;

    case "contacttrader":
      chatbotData = {
        ...chatbotData,
        text: chatbot_const_text.no.line_1,
        sendasclient: 1,
        button_options: {
          questions_type: "categories",
        },
      };
      break;

    case "contacttrader_greeting":
      chatbotData = {
        ...chatbotData,
        text: chatbot_const_text.contact_trader,
        sendasclient: 1,
      };
      break;

    case "yes":
      chatbotData = {
        ...chatbotData,
        text: chatbot_const_text.yes.line_1,
        sendasclient: 1,
      };
      break;
    case "more_option_satisfied":
      chatbotData = {
        ...chatbotData,
        text: chatbot_const_text.yes.line_2,
        sendasclient: 1,
        button_options: {
          questions_type: "categories",
        },
      };
      break;

    case "send_as_client_message":
      chatbotData = {
        ...chatbotData,
        sendasclient: 1,
      };

      break;

    case "answer_message":
      chatbotData = {
        ...chatbotData,
        sendasclient: 1,
        button_options: {
          questions_type: "answers",
        },
      };

      break;

    case "send_as_user_message":
      chatbotData = {
        ...chatbotData,
        sendasclient: 0,
      };

      break;

    case "after_categories_2":
      chatbotData = {
        ...chatbotData,
        text: chatbot_const_text.category_option.line_2,
        sendasclient: 1,
        button_options: {
          questions_type: questions_type,
          questions_id: questions_id,
        },
      };
      break;

    default:
      break;
  }
  QB_sendMessage({
    text: chatbotData.text,
    dialog_id: chatbotData.dialog_id,
    opponentId: chatbotData.opponentId,
    type: chatbotData.type,
    topic_type: chatbotData.topic_type,
    sendasclient: chatbotData.sendasclient,
    button_options: chatbotData.button_options,
    sendmail: false,
  });
};

export const deepCopy = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export const checkQBexpiry = (session: any) => {
  if (!session?.session?.token) return true;
  const created_at = new Date();
  const unixTimestamp = Math.floor(created_at.getTime() / 1000);
  const decoded = jwtDecode(session.session.token);
  const expired_at = decoded.exp ? decoded.exp : 0;
  return unixTimestamp > expired_at;
};
