import { updateDialog } from "../../store/features/Messages.slice";
import store from "../../store/store";
import { QB } from "../QuickBlox_initiation";

function QB_dialogUpdate(id: string) {
  let listparams = {
    _id: id,
  };
  QB.chat.dialog.list(listparams, function (error: any, dialogs: any) {
    const updatedDailog = dialogs.items[0];
    if (!error) {
      store.dispatch(updateDialog(updatedDailog));
    }
  });
}

export default QB_dialogUpdate;
