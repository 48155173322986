import React from "react";
import { useAppSelector } from "../hooks/reduxHooks";
import { Navigate } from "react-router-dom";
import { ModalLoader } from "../components/Loader/Loader";

const IndexPage = () => {
  const offers_list_home_loaded = useAppSelector(
    (state) => state.OffersReducer.offers_list_home_loaded
  );
  const offers_list_home = useAppSelector(
    (state) => state.OffersReducer.offers_list_home
  );

  let indexpage = "index";
  if (offers_list_home.length === 0) {
    indexpage = "grades";
  }
  if (!offers_list_home_loaded)
    return (
      <>
        <ModalLoader show={false} />
      </>
    );

  return <Navigate to={indexpage} />;
};
export default IndexPage;
