import dayjs from "dayjs";
import {
  ChatInterface,
  MessageDialogListInterface,
} from "../../interfaces/MessagesInterface";
import { toggleChatbotTyping } from "../../store/features/Common.slice";
import {
  updateChat,
  updateDialog,
  updateGoEnd,
} from "../../store/features/Messages.slice";
import store from "../../store/store";
import { globalNavigate } from "../GlobalNavigator";
import QB_retriveChatHistory from "./QB_retriveChatHistory";
import { deepCopy } from "../utils";
import QB_dialogUpdate from "./QB_dialogUpdate";

function QB_onMessageListener(userId: any, message: any) {
  const state = store.getState();
  const {
    user_id,
    dialog_list = {},
    active_dialog_id,
    backend_details,
    global_admin_details,
    user_id_n_name,
    chatbot_id,
  } = state.MessagesReducer;
  const { chatbot_const_text } = state.CommonReducer;

  let dialog: MessageDialogListInterface = {} as MessageDialogListInterface;

  if (dialog_list[message.dialog_id]) {
    const update_date = new Date(message.extension.date_sent * 1000);
    let temp = deepCopy(dialog_list[message.dialog_id]);
    dialog = temp;
    temp.last_message = message.body;
    temp.last_message_user_id = userId;
    temp.last_message_id = message.id;
    temp.last_message_date_sent = dayjs(update_date).unix();
    if (active_dialog_id !== message.dialog_id && user_id !== userId) {
      temp.unread_messages_count += 1;
    }
    if (user_id === userId || active_dialog_id === message.dialog_id) {
      temp.unread_messages_count = 0;
    }
    store.dispatch(updateDialog(temp));
  } else {
    QB_dialogUpdate(message.dialog_id);
    dialog._id = message.dialog_id;
  }

  const d = new Date().toJSON();
  let notificationBody = message.body;
  let newMessage: ChatInterface = {
    _id: message.id,
    message: message.body,
    sender_id: userId,
    updated_at: d,
    created_at: d,
    read: 1,
    dialog_id: message.dialog_id,
    sendasclient:
      chatbot_id && +userId === +chatbot_id
        ? 1
        : +message.extension.sendasclient,
  };
  if (message.extension) newMessage = { ...message.extension, ...newMessage };

  if (message?.extension?.attachments) {
    const newAttached = JSON.parse(
      JSON.stringify(message?.extension?.attachments)
    );
    const attachmentsType = newAttached[0].type;
    notificationBody =
      "sent a" + attachmentsType === "image" ? "n image" : " video";
    newMessage.attachments = message?.extension?.attachments;
  }

  store.dispatch(
    updateChat({
      id: message.dialog_id,
      message: newMessage,
      chatbot_const_text: chatbot_const_text,
    })
  );
  if (chatbot_id && +chatbot_id === +newMessage.sender_id)
    store.dispatch(toggleChatbotTyping(false));

  store.dispatch(updateGoEnd(false));

  const allowNotification = true;
  if (allowNotification) {
    const permission = Notification.permission;
    if (permission !== "granted") {
      Notification.requestPermission(function (permission) {});
    }
  }
  const permission = Notification.permission;

  const isDifferentUser = user_id !== +userId;
  const notActiveDialog = active_dialog_id !== message.dialog_id;
  const notBEuser = backend_details.id !== +userId;
  const notGBuser = global_admin_details.id !== +userId;
  const isGranted = permission === "granted";
  const isNotChatbot = (chatbot_id ? +chatbot_id : 0) !== +userId;

  if (
    isDifferentUser &&
    notActiveDialog &&
    notBEuser &&
    notGBuser &&
    allowNotification &&
    isGranted &&
    isNotChatbot
  ) {
    console.log("jhi");
    const title =
      backend_details.id !== userId
        ? user_id_n_name?.[userId]
          ? user_id_n_name?.[userId]
          : "Hubbit Message"
        : "Update !!!";

    const notification = new Notification(title, {
      body: notificationBody,
      icon: "https://d322n67a984ywx.cloudfront.net/icons/logo.png",
    });
    notification.onclick = () => {
      notification.close();

      globalNavigate("/home/messages?dialog_id=" + dialog._id);
      QB_retriveChatHistory(dialog);
      window.parent.focus();
    };
  }
}

export default QB_onMessageListener;
