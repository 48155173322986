import { IGrade } from "../../interfaces/IGradeReducer";
import store from "../../store/store";
import { capitalizeFirstLetter } from "../../utils/utils";
import {
  mysHeapConst,
  MYSHeapUnitConversion,
  MYSLoadTermsData,
  MYSmaterialsLabel,
} from "./MYSconst";
// Pricing, Home, Draft, Duplicate Price Request, Sell Again, My Grades
export const heap_startsale = (pricing_details: IGrade) => {
  let path = localStorage.getItem("card_path");
  let priced_grade = "No";
  let grade_unit = "LB";
  let unit_price_on_card = null;

  if (pricing_details.best_price) {
    unit_price_on_card = pricing_details.best_price;
  }
  if (pricing_details.unit) {
    if (MYSHeapUnitConversion[pricing_details.unit.toUpperCase()])
      grade_unit = MYSHeapUnitConversion[pricing_details.unit.toUpperCase()];
  }
  if (pricing_details.price_type === "Available for Deal") priced_grade = "Yes";

  let data: any = {
    path,
    grade: pricing_details.name,
    grade_id: pricing_details.id,
    grade_unit: grade_unit,
    priced_grade: priced_grade,
    unit_price_on_card: unit_price_on_card,
    grade_type: pricing_details.category,
    grade_subcategory_1: pricing_details.sub_category_1,
    grade_subcategory_2: pricing_details.sub_category_2,
  };

  heap.track(mysHeapConst.start_sale, data);
};

export const heap_formOneSubmit = () => {
  const data = dataFormatter({ type: "form_one_submit" });
  heap.track(mysHeapConst.form_one_submit, data);
};

export const heap_formOneHybrid = () => {
  const data = dataFormatter({ type: "form_one_hybrid" });
  heap.track(mysHeapConst.form_one_hybrid, data);
};

export const heap_formOneHybridOrder = () => {
  const data = dataFormatter({ type: "form_one_hybrid_order" });
  heap.track(mysHeapConst.form_one_hybrid_order, data);
};

export const heap_formOneHybridOffer = () => {
  const data = dataFormatter({ type: "form_one_hybrid_offer" });
  heap.track(mysHeapConst.form_one_hybrid_offer, data);
};

export const heap_formTwoSubmit = () => {
  const data = dataFormatter({ type: "form_two_submit" });
  heap.track(mysHeapConst.form_two_submit, data);
};

export const heap_formThreeSubmit = () => {
  const data = dataFormatter({ type: "form_three_submit" });
  heap.track(mysHeapConst.form_three_submit, data);
};

export const heap_formFourOffer = () => {
  const data = dataFormatter({ type: "form_four_offer" });
  heap.track(mysHeapConst.form_four_offer, data);
};

export const heap_formFourOrder = () => {
  const data = dataFormatter({ type: "form_four_order" });
  heap.track(mysHeapConst.form_four_order, data);
};

export const heap_formSaveDraft = () => {
  const data = dataFormatter({ type: "draft" });
  heap.track(mysHeapConst.save_draft, data);
};

export const heap_closeSaveDraft = () => {
  const data = dataFormatter({ type: "draft" });
  heap.track(mysHeapConst.close_save_draft, data);
};

export const heap_selectRecyclerType = ({ recycler_type = "" }) => {
  const data = {
    recycler_type,
  };
  heap.track(mysHeapConst.select_recyler_type, data);
};

export const heap_selectPackingType = ({
  packing_type = "",
  recycler_type = "",
}) => {
  const data = {
    packing_type,
    recycler_type,
  };
  heap.track(mysHeapConst.select_packing_type, data);
};

export const heap_selectSellOption = ({
  sell_option = "",
  recycler_type = "",
}) => {
  const data = {
    sell_option,
    recycler_type,
  };
  heap.track(mysHeapConst.select_sell_option, data);
};

export const heap_selectFreightType = ({
  freight_type = "",
  recycler_type = "",
}) => {
  const data = {
    freight_type,
    recycler_type,
  };
  heap.track(mysHeapConst.select_freight_type, data);
};

export const heap_selectLoadTerms = ({
  load_terms = "",
  recycler_type = "",
}) => {
  const data = {
    load_terms,
    recycler_type,
  };
  heap.track(mysHeapConst.select_load_terms, data);
};

export const heap_enterWeight = ({
  total_weight = "",
  supplier_weight_unit = "",
  recycler_type = "",
}) => {
  const data = {
    total_weight,
    supplier_weight_unit,
    recycler_type,
  };
  heap.track(mysHeapConst.enter_weight, data);
};

export const heap_enterLoads = ({ loads = "", recycler_type = "" }) => {
  const data = {
    loads,
    recycler_type,
  };
  heap.track(mysHeapConst.enter_loads, data);
};

export const heap_selectLocation = ({
  address_name = "",
  address_city = "",
  address_state = "",
  address_zip = "",
  custom_address = "No",
  recycler_type = "",
}) => {
  const data = {
    address_name,
    address_city,
    address_state,
    address_zip,
    custom_address,
    recycler_type,
  };
  heap.track(mysHeapConst.select_location, data);
};

const dataFormatter = ({ type = "" }) => {
  const {
    pricing_details,
    form_one,
    form_one_response,
    form_two,
    form_three,
    sale_info,
    port_name_with_terminal_id,
    port_terminal_map,
    pickup_address_list_map,
  } = store.getState().MakeYourSaleReducer;
  let path = localStorage.getItem("card_path");
  let priced_grade = "No";

  if (pricing_details.price_type === "Available for Deal") priced_grade = "Yes";
  let grade_unit = "LB";
  if (pricing_details.unit) {
    if (MYSHeapUnitConversion[pricing_details.unit.toUpperCase()])
      grade_unit = MYSHeapUnitConversion[pricing_details.unit.toUpperCase()];
  }
  let data: any = {
    path,
    grade: pricing_details.name,
    grade_id: pricing_details.id,
    grade_unit: grade_unit,
    priced_grade: priced_grade,
    grade_type: pricing_details.category,
    grade_subcategory_1: pricing_details.sub_category_1,
    grade_subcategory_2: pricing_details.sub_category_2,
  };
  if (type === "start") return data;

  let isOrder = false;

  let recycler_type = null;
  let material_type = null;
  let sell_option = null;
  let supplier_weight_unit = null;
  let total_weight = null;
  let unit_price = null;
  let frieght_type = null;
  let ship_type = null;
  let load_terms = null;
  let total_load = null;
  let location = null;
  let address_name = null;
  let address_city = null;
  let address_state = null;
  let address_zip = null;

  if (form_two.flow_type === "ORDER") isOrder = true;

  if (form_one.sale_type) {
    recycler_type = capitalizeFirstLetter("" + form_one.sale_type);
    if (form_one.min_weight_unit)
      supplier_weight_unit = form_one.min_weight_unit.toUpperCase();
  }
  if (form_one.material_type) {
    material_type = MYSmaterialsLabel[form_one.material_type];
  }
  if (form_one.sell_option) {
    sell_option = capitalizeFirstLetter("" + form_one.sell_option);
  }
  if (isOrder) {
    if (form_one_response.order_details?.total_weight)
      total_weight = form_one_response.order_details?.total_weight;
    if (form_one_response.order_details?.unit_price)
      unit_price = form_one_response.order_details?.unit_price;
  } else {
    if (form_one_response.offer_details?.total_weight)
      total_weight = form_one_response.offer_details?.total_weight;
  }
  if (form_one.freight_option) {
    frieght_type = form_one.freight_option.includes("FAS") ? "FAS" : "FOB";
  }
  if (form_one.freight_option) {
    ship_type = form_one.freight_option;
  }
  if (form_one.load_terms) {
    load_terms = MYSLoadTermsData[form_one.load_terms];
  }
  if (form_one.min_weight_load) {
    total_load = form_one.min_weight_load;
  }
  if (form_one.new_address) {
    address_name = form_one.new_address;
  }
  if (form_one.new_city) {
    address_city = form_one.new_city;
    location = form_one.new_city;
  }
  if (form_one.new_state) {
    address_state = form_one.new_state;
  }
  if (form_one.new_zip_code) {
    address_zip = form_one.new_zip_code;
  }

  if (form_one.port) {
    let terminal_id = port_terminal_map[+form_one.port];
    location = port_name_with_terminal_id[terminal_id];
    address_city = port_name_with_terminal_id[terminal_id];
    address_name = port_name_with_terminal_id[terminal_id];
  }
  if (form_one.pickup_address) {
    location = pickup_address_list_map[+form_one.pickup_address].city;
    address_name = pickup_address_list_map[+form_one.pickup_address].address;
    address_city = pickup_address_list_map[+form_one.pickup_address].city;
    address_state = pickup_address_list_map[+form_one.pickup_address].state;
    address_zip = pickup_address_list_map[+form_one.pickup_address].zip_code;
  }
  data = {
    ...data,
    recycler_type: recycler_type,
    packing_type: material_type,
    sell_option: sell_option,
    supplier_weight_unit: supplier_weight_unit,
    frieght_type: frieght_type,
    ship_type: ship_type,
    load_terms: load_terms,
    loads: total_load,
    total_weight: total_weight,
    unit_price: unit_price,
    address_name: address_name,
    address_city: address_city,
    address_state: address_state,
    address_zip: address_zip,
    location: location,
    priced_grade: priced_grade,
  };

  if (type === "form_one_submit") {
    return data;
  }
  const hybirdHeapsValues = [
    "form_one_hybrid",
    "form_one_hybrid_order",
    "form_one_hybrid_offer",
  ];
  if (hybirdHeapsValues.includes(type)) {
    data = {
      ...data,
      supplier_entered_loads: form_one_response.loads,
      sale_loads: form_one_response.order_details?.loads,
    };
    if (type === "form_one_hybrid_order") {
      data = {
        ...data,
        trade_choice: "Sale",
      };
      return data;
    }
    if (type === "form_one_hybrid_offer") {
      data = {
        ...data,
        trade_choice: "Price Request",
      };
      return data;
    }
    return data;
  }

  let load_distribution = null;
  let total_price = null;

  if (isOrder) {
    if (form_one_response.order_details?.load_distribution)
      load_distribution = form_one_response.order_details?.load_distribution;
    if (form_one_response.order_details?.total_price)
      total_price = form_one_response.order_details?.total_price;
  }

  data = {
    ...data,
    min_weight: form_one.min_weight,
    loads: form_one_response.loads,
    load_distribution: load_distribution,
    total_price: total_price,
    trade_type: form_one_response.trade_type,
  };
  if (type === "form_two_submit") return data;

  let images = "No";
  let delivery_schedule = "No";
  let additional_info = "No";
  if (form_three.grade_images.length > 0) images = "Yes";

  if (form_three.delivery_schedule) {
    if (form_three.delivery_schedule.length > 0) delivery_schedule = "Yes";
  }
  if (form_three.notes !== "") additional_info = "Yes";
  data = {
    ...data,
    delivery_schedule: delivery_schedule,
    images: images,
    additional_info: additional_info,
  };

  if (type === "form_three_submit") return data;
  if (type === "draft") return data;

  let isFas = false;
  let region = null;
  let from_address = null;
  let from_address_name = null;
  let from_address_city = null;
  let from_address_state = null;

  let to_address = null;
  let to_address_name = null;
  let to_address_city = null;
  let to_address_state = null;

  if (sale_info.freight_type && sale_info.freight_type.toUpperCase() === "FAS")
    isFas = true;

  if (sale_info.preferred_location.region)
    region = sale_info.preferred_location.region;

  //setting from address
  if (isFas) {
    if (sale_info.preferred_location.address)
      from_address = sale_info.preferred_location.address;

    if (sale_info.preferred_location.name)
      from_address_name = sale_info.preferred_location.name;

    if (sale_info.preferred_location.city)
      from_address_city = sale_info.preferred_location.city;

    if (sale_info.preferred_location.state)
      from_address_state = sale_info.preferred_location.state;
  } else {
    if (sale_info?.offer_details) {
      if (sale_info?.offer_details?.shipment_information.address)
        from_address = sale_info?.offer_details?.shipment_information.address;

      if (sale_info?.offer_details?.shipment_information.name)
        from_address_name = sale_info?.offer_details?.shipment_information.name;

      if (sale_info?.offer_details?.shipment_information.city)
        from_address_city = sale_info?.offer_details?.shipment_information.city;

      if (sale_info?.offer_details?.shipment_information.state)
        from_address_state =
          sale_info?.offer_details?.shipment_information.state;
    } else {
      if (sale_info.shipping_location.address)
        from_address = sale_info.shipping_location.address;

      if (sale_info.shipping_location.name)
        from_address_name = sale_info.shipping_location.name;

      if (sale_info.shipping_location.city)
        from_address_city = sale_info.shipping_location.city;

      if (sale_info.shipping_location.state)
        from_address_state = sale_info.shipping_location.state;
    }
  }

  //setting to address
  if (sale_info.to_address) {
    if (sale_info.to_address.to_address)
      to_address = sale_info.to_address.to_address;

    if (sale_info.to_address.to_address_name)
      to_address_name = sale_info.to_address.to_address_name;

    if (sale_info.to_address.to_address_city)
      to_address_city = sale_info.to_address.to_address_city;

    if (sale_info.to_address.to_address_state)
      to_address_state = sale_info.to_address.to_address_state;
  }

  data = {
    ...data,
    region: region,
    from_address: from_address,
    from_address_name: from_address_name,
    from_address_city: from_address_city,
    from_address_state: from_address_state,
    to_address: to_address,
    to_address_name: to_address_name,
    to_address_city: to_address_city,
    to_address_state: to_address_state,
  };

  if (type === "form_four_offer") {
    data = {
      ...data,
      offer_id: sale_info.id,
      offer_status: "Waiting for Trader",
      offer_type: "Request Price",
      starting_supplier_price: unit_price,
    };
  }

  let margin = null;
  let commission = null;
  let freight = null;
  let drayage = null;
  if (sale_info.pricing_details) {
    if (sale_info.pricing_details.margin)
      margin = sale_info.pricing_details.margin;
    if (sale_info.pricing_details.commission)
      commission = sale_info.pricing_details.commission;
    if (sale_info.pricing_details.commission)
      freight = sale_info.pricing_details.freight;
    if (sale_info.pricing_details.commission)
      drayage = sale_info.pricing_details.drayage;
  }
  if (type === "form_four_order") {
    data = {
      ...data,
      order_id: sale_info.id,
      order_status: "New",
      margin: margin,
      commission: commission,
      freight: freight,
      drayage: drayage,
      from_offer: "No",
    };
  }
  return data;
};

export const heap_ViewMYSAssistance = (data: any) => {
  heap.track(mysHeapConst.view_assistance, data);
};

export const heap_CloseMYSAssistance = (data: any) => {
  heap.track(mysHeapConst.close_assistance, data);
};

export const heap_mys_schedule_call = (data: any) => {
  heap.track(mysHeapConst.schedule_call, data);
};

export const heap_mys_contact_trader = (data: any) => {
  heap.track(mysHeapConst.contact_trader, data);
};

export const heap_mys_dont_show = (data: any) => {
  heap.track(mysHeapConst.dont_show_anymore, data);
};
